$('[data-toggle="menu"]').click(function () {
	var target= $(this).data('target');
	var rootTarget = document.querySelector(target);
	var bgMenu = document.createElement('div');

	$(bgMenu).addClass('bg-menu');
	$(bgMenu).click(function(){
		$(target).removeClass('open');
		$('body, html').removeClass('lock-scroll');

		$(this).fadeOut(1000, function(){
			$(this).remove();
		});
	});

	rootTarget.appendChild(bgMenu);

	$(target).addClass('open');
	$('body, html').addClass('lock-scroll');
	$(bgMenu).fadeIn(1000);
});