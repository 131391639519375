(function(){
	window.midia = 'XS';
	var jaLazyPicture = false;

	function verifyMidia(){
		var w = window.innerWidth;

		if(w < 	576)	{window.midia = 'XS'}
		if(w >= 576)	{window.midia = 'SM'}
		if(w >= 768)	{window.midia = 'MD'}
		if(w >= 992)	{window.midia = 'LG'}
		if(w >= 1200)	{window.midia = 'XL'}

		console.log('Midia Size: '+window.midia)
	}



	function LazyPictures(){
		verifyMidia();
		
		var lazypictures = document.querySelectorAll('lazy-picture');

		if(lazypictures){
			for(var i=0;i<lazypictures.length;i++){
				var lz = lazypictures[i];

				if(!lz.images){
					lz.images = {};
					
					for(var c=0;c<lz.children.length;c++){
						var child = lz.children[c];
						var src = child.getAttribute('src');
						var media = child.nodeName.split('-')[0];


						if(!src){
							lz.removeChild(child);
						}else{
							lz.images[media] = src;
						}
					}
				}

				if(lz.getBoundingClientRect().top <= (window.innerHeight + 200)){
					var media = window.midia.toLowerCase();

					var canvas = lz.querySelector(media+'-src');

					if(!canvas.getAttribute('loaded')){
						var img = document.createElement('img');

						img.src = lz.images[window.midia];
						img.alt = lz.getAttribute('alt') || 'placehold image';

						img.setAttribute('class', 'img-fluid');

						canvas.appendChild(img);

						canvas.setAttribute('loaded', 'true');
					}
				}
			}
		}
	}

	LazyPictures();

	window.addEventListener('resize', function(){
		if(jaLazyPicture) return;

		setTimeout(function(){
			jaLazyPicture = false;
		}, 100);

		LazyPictures();
	});

	window.addEventListener('scroll', function(){
		if(jaLazyPicture) return;

		setTimeout(function(){
			jaLazyPicture = false;
		}, 100);

		LazyPictures();
	});

})();